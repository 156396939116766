<template>
  <div>

    <b-form-group
        :label-for="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
        :label="displayLabel?capitalize($tc(name)):null"
        class="w-100"
    >
      <validation-provider
          v-if="isRequired"
          #default="{ errors }"
          :name="$tc(name)+ (listIndex != -1?(' '+(listIndex+1)):'')"
          rules="required"
      >
        <v-select
            :id="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
            :multiple="multiple"
            v-model="localModel"
            :label="label"
            :options="options"
            :placeholder="placeholder!=''?placeholder:($t('Choose a ') + $tc(name).toLowerCase())"
            :selectable="(option) => !option.disabled"
            :clearable="clearable"
            :disabled="disabled"
            :append-to-body="appendToBody"
            :getOptionKey="getOptionKey"
            class="w-100"
        >
          <template v-slot:option="option">
            <slot name="customList"
                  v-bind:option="option">
              <!-- Default content -->
              <span v-if="option[reduce] == '-'">
                <b-dropdown-divider></b-dropdown-divider>
              </span>
              <span v-else-if="label != 'label'">{{ option[label] }}</span>
              <span v-else>{{ option[name] }}</span>
            </slot>
          </template>

          <template
              v-if="allowAdd"
              #list-header
          >
            <slot name="list-header">
              <li
                  @click="$emit('addCallback')"
                  class="add-new-client-header d-flex align-items-center my-50 cursor-pointer"
              >
                <icon icon="plus"/>
                <span class="align-middle ml-50">{{ $t(addText) }}</span>
              </li>
            </slot>
          </template>

          <template #no-options="{ search, searching, loading }">
            {{ $t('NoResult') }}
          </template>
        </v-select>


        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>

      <v-select
          v-else
          :id="name+ (listIndex != -1?('_'+(listIndex+1)):'')"
          :multiple="multiple"
          v-model="localModel"
          :label="label"
          :options="options"
          :placeholder="placeholder!=''?placeholder:($t('Choose a ') + $tc(name).toLowerCase())"
          :selectable="(option) => !option.disabled"
          :clearable="clearable"
          :disabled="disabled"
          :append-to-body="appendToBody"
          :getOptionKey="getOptionKey"
          class="w-100"
      >
        <template v-slot:option="option">
          <slot name="customList"
                v-bind:option="option">
            <!-- Default content -->
            <span v-if="option[reduce] == '-'">
              <b-dropdown-divider></b-dropdown-divider>
            </span>
            <span v-else-if="label != 'label'">{{ option[label] }}</span>
            <span v-else>{{ option[name] }}</span>
          </slot>
        </template>

        <template
            v-if="allowAdd"
            #list-header
        >
          <slot name="list-header">
            <li
                @click="$emit('addCallback')"
                class="add-new-client-header d-flex align-items-center my-50 cursor-pointer"
            >
              <icon icon="plus"/>
              <span class="align-middle ml-50">{{ $t(addText) }}</span>
            </li>
          </slot>
        </template>

        <template #no-options="{ search, searching, loading }">
          {{ $t('NoResult') }}
        </template>
      </v-select>

    </b-form-group>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { required } from '@/utils/validations/validations'
import { capitalize } from '@/utils/filter'

import vSelect from 'vue-select'
import { isObject } from '../../utils/utils'

export default {
  components: {
    vSelect,
    ValidationProvider
  },
  props: {
    model: {
      // required: true
    },
    name: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },

    // Not required
    displayLabel: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: '_display'
    },
    reduce: {
      type: String,
      default: 'id'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    allowAdd: {
      type: Boolean,
      default: false
    },
    addText: {
      type: String,
      default: 'Add'
    },
    placeholder: {
      type: String,
      default: ''
    },
    clearable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
    appendToBody: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.model, val => {
        localModel.value = val
    })

    watch(localModel, val => {
      emit('update:model', val)
      emit('input', val)
      emit('updated')
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const getOptionKey = (option) => {
      if (typeof option === 'object' && option._uuid) {
        return option._uuid
      } else if (typeof option === 'object' && option.id) {
        return option.id
      } else {
        try {
          return JSON.stringify(option)
        } catch (e) {
          return console.warn(
              `[vue-select warn]: Could not stringify option ` +
              `to generate unique key. Please provide 'getOptionKey' prop ` +
              `to return a unique key for each option.\n` +
              'https://vue-select.org/api/props.html#getoptionkey'
          )
          return null
        }
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (props.model) {
      if (isObject(props.model)) {
        if ((props.label in props.model)) {
          localModel.value = props.model
        } else {
          localModel.value = props.options.find(o => o.id == props.model.id)
        }
      } else {
        localModel.value = props.options.find(o => o.id == props.model)
      }
    }

    return {
      // Components
      capitalize,
      required,

      // Data
      localModel,

      // Computed

      // Methods
      getOptionKey,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>