<template>
    <b-row>
      <b-col
          cols="12"
          md="3"
          v-for="(item, index) in items"
          :key="index"
      >
        <slot
            name="gridView"
            :item="item"
        />
      </b-col>

    </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {},
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>