<template>
  <div>

    <b-row>

      <!--      FirstName-->
      <b-col md="6">
        <field
            :name="'firstName'"
            :rules="'required'"
            :model.sync="user.firstName"
            @input="updateDisplayField('firstName', $event)"
        />
      </b-col>

      <!--      Name-->
      <b-col md="6">
        <field
            :name="'name'"
            :rules="'required'"
            :model.sync="user.name"
            @input="updateDisplayField('name', $event)"
        />
      </b-col>

      <!--      Company-->
      <b-col
          v-if="displayCompany"
          md="6"
      >
        <field-select
            :name="'company'"
            :model.sync="user.company"
            :options="companies"
            :label="'_display'"
        >
        </field-select>
      </b-col>

      <!--          Phone-->
      <b-col md="6">
        <field
            :name="'phone'"
            :rules="'phone'"
            :model.sync="user.phone"
        />
      </b-col>

      <!--      Email-->
      <b-col md="6">
        <field
            :name="'email'"
            :rules="'email'"
            :model.sync="user.email"
        />
      </b-col>


    </b-row>

  </div>
</template>

<script>
import { ref, toRefs, computed, watch } from '@vue/composition-api'

import useAPI from '@/utils/useAPI'
import FieldSelect from '@/components/input/Select'
import Field from '@/components/input/Input'

export default {
  components: {
    Field,
    FieldSelect,
  },
  props: {
    user: {
      type: Object,
      default: () => {}
    },
    displayCompany: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { companies } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch('user.name', (val) => {
    //   console.log(val)
    // })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateDisplayField = (field, val) => {
      if (field == 'firstName') {
        if (props.user.name) {
          props.user._display = val + ' ' + props.user.name
        } else {
          props.user._display = val
        }
      }

      if (field == 'name') {
        if (props.user.firstName) {
          props.user._display = props.user.firstName + ' ' + val
        } else {
          props.user._display = val
        }
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      companies,
      updateDisplayField
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>