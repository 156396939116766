<template>
  <b-form-group
      :label-for="idLocal"
      :label="displayLabel?capitalize($t(name)):''"
  >
    <b-form-checkbox
        :class="position=='right'?'float-right':''"
        :id="idLocal"
        v-model="localModel"
        switch
    >
      <span class="switch-icon-left">
        <slot name="iconLeft">
          <icon icon="check"/>
        </slot>
      </span>
      <span class="switch-icon-right">
        <slot name="iconRight">
          <icon icon="times"/>
        </slot>
      </span>
    </b-form-checkbox>
  </b-form-group>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'

export default {
  components: {},
  props: {
    model: {
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
    position: {
      type: String,
      default: 'left'
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)
    const idLocal = ref(props.name+ '-'+ Math.random())

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localModel, () => {
      // console.log(localModel.value)
      emit('update:model', localModel.value)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      localModel,
      idLocal,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>