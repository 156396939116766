<template>
  <div>

    <base-template
        title="CustomerInvoices"
        itemView="list"
        :text-button-add="$t('Add')"
        :columns="columns"
        :filters="filters"
        :items="outgoingInvoices2"
        :context="outgoingInvoicesContext"
        :isActionColumnEnabled="true"
        :open-view-window="openViewWindow"
        :allow-add="allowAdd"
        :display-custom-fields="displayCustomFields&&allowAdd"

        @fetch="$emit('reloadCustomerInvoices', $event)"
        @selectItem="showDetails"
        @addItem="customerInvoiceModalShow=true"
    >
      <template #bottomTitle>
        <i v-if="outgoingInvoicesContext.custom_fields && displayCustomFields && !allowAdd">
          {{ currency(outgoingInvoicesContext.custom_fields.preTaxAmounts) }} HT /
          {{ currency(outgoingInvoicesContext.custom_fields.totalAmounts) }} TTC
        </i>
      </template>

      <!--  =========================== -->
      <!--  ===      Grid view      === -->
      <!--  =========================== -->
      <template v-slot:gridView="{item}">
        <outgoing-invoice-card
            :outgoing-invoice="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-payable="allowPayment"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>

      <!--  =========================== -->
      <!--  ===      List view      === -->
      <!--  =========================== -->
      <!--      Actions-->
      <template #listView_cell_actions="{item}">
        <div class="text-nowrap">

          <!--          View-->
          <button-view
              @click.native.stop="showDetails(item)"
              v-b-tooltip.hover.left="$t('View')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Download-->
          <button-download
              @click.native.stop="downloadOutgoingInvoice(item)"
              v-b-tooltip.hover.left="$t('Download')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Edit -->
          <button-edit
              v-if="allowEdit"
              @click.native.stop="$router.push({ name: 'OutgoingInvoice edit', params: { outgoingInvoiceId: item.id }})"
              v-b-tooltip.hover.left="$t('Edit')"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Duplicate-->
          <button-duplicate
              v-if="allowAdd"
              v-b-tooltip.hover.left="capitalize($t('duplicate'))"
              @click.native.stop="duplicateCustomerInvoice(item)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Validate-->
          <button-validate
              v-if="item.billNumber == null"
              @click.native.stop="validateOutgoingInvoice(item)"
              v-b-tooltip.hover.left="capitalize($t('validate'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Payment-->
          <button-payment
              v-if="allowPayment"
              @click.native.stop="addPayment([item])"
              v-b-tooltip.hover.left="capitalize($t('addPayment'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Archivate-->
          <button-archivate
              v-if="item.isArchived == false"
              @click.native.stop="archivateOutgoingInvoiceLocal(item, true)"
              v-b-tooltip.hover.left="capitalize($t('archivate'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Unarchivate-->
          <button-unarchivate
              v-if="item.isArchived == true"
              @click.native.stop="archivateOutgoingInvoiceLocal(item, false)"
              v-b-tooltip.hover.left="capitalize($t('archivate'))"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

          <!--          Delete-->
          <button-remove
              v-if="allowRemove"
              v-b-tooltip.hover.left="capitalize($t('delete'))"
              @click.native.stop="deleteCustomerInvoiceAlert(item)"
              :withIcon="true"
              :withText="false"
              :withBorder="false"
              size="sm"
          />

        </div>
      </template>


      <!--  =========================== -->
      <!--  ===     Select view     === -->
      <!--  =========================== -->
      <template #leftViewWindow="{item}">
        <outgoing-invoice-card
            :outgoing-invoice="item"
            :is-downloadable="false"
            :is-viewable="true"
            :is-selected="item.id==selectedItems[0].id"

            @click="showDetails(item)"
            @view="showDetails(item)"
        />
      </template>
      <template #windowView
                v-if="selectedItems.length">
        <div class="d-flex justify-content-between mb-1">
          <h4 class="text-truncate">{{ selectedItems[0]._display }}</h4>

          <div class="text-nowrap">
            <!--          Download-->
            <button-download
                @click.native.stop="downloadOutgoingInvoice(selectedItems[0])"
                v-b-tooltip.hover.left="$t('Download')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Edit -->
            <button-edit
                v-if="allowEdit"
                @click.native.stop="$router.push({ name: 'OutgoingInvoice edit', params: { outgoingInvoiceId: selectedItems[0].id }})"
                v-b-tooltip.hover.left="$t('Edit')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />
            <!--          Duplicate-->
            <button-duplicate
                v-if="allowAdd"
                v-b-tooltip.hover.left="capitalize($t('duplicate'))"
                @click.native.stop="duplicateCustomerInvoice(selectedItems[0])"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Validate-->
            <button-validate
                v-if="selectedItems[0].billNumber == null"
                @click.native.stop="validateOutgoingInvoice(selectedItems[0])"
                v-b-tooltip.hover.left="capitalize($t('validate'))"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Payment-->
            <button-payment
                v-if="allowPayment"
                @click.native.stop="addPayment([selectedItems[0]])"
                v-b-tooltip.hover.left="capitalize($t('addPayment'))"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Archivate-->
            <button-archivate
                v-if="selectedItems[0].isArchived == false"
                @click.native.stop="archivateOutgoingInvoiceLocal(selectedItems[0], true)"
                v-b-tooltip.hover.left="capitalize($t('archivate'))"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Unarchivate-->
            <button-unarchivate
                v-if="selectedItems[0].isArchived == true"
                @click.native.stop="archivateOutgoingInvoiceLocal(selectedItems[0], false)"
                v-b-tooltip.hover.left="capitalize($t('archivate'))"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />

            <!--          Close-->
            <button-close
                @click.native.stop="showDetails(null)"
                v-b-tooltip.hover.left="$t('Close')"
                :withIcon="true"
                :withText="false"
                :withBorder="false"
                size="sm"
            />
          </div>
        </div>

        <view-outgoing-invoice
            class="invoice-preview"
            :invoice="selectedItems[0]"
        />


      </template>
    </base-template>

    <modal-payment
        :payment.sync="currentPayment"
        :title="'id' in currentPayment?$t('EditPayment'):$t('NewPayment')"
        :isOpen.sync="paymentModalShow"
        :formMaxAmount="maxPaymentAmount"

        @submitValidated="submitValidatedPaymentLocal"
    />


    <!-- modal outgoing invoice-->
    <modal-outgoing-invoice
        :outgoingInvoice.sync="currentCustomerInvoice"
        :title="$t('NewInvoice')"
        :isOpen.sync="customerInvoiceModalShow"
        @submitValidatedOutgoingInvoice="submitValidatedCustomerInvoiceLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize, currency } from '../../utils/filter'
import { usePayments } from '../../views/accounting/usePayment'
import { useCustomerInvoices } from '../../views/sales/customerInvoice/useCustomerInvoice'

import BaseTemplate from '../base/Base'
import ButtonView from '../button/View'
import ButtonPayment from '../button/Payment'
import ButtonDownload from '../button/Download'
import ButtonClose from '../button/Close'
import ButtonEdit from '../button/Edit.vue'
import ButtonValidate from '../button/Validate.vue'
import ButtonArchivate from '../button/Archivate.vue'
import ButtonUnarchivate from '../button/Unarchivate.vue'
import ButtonDuplicate from '../button/Duplicate.vue'
import ButtonRemove from '../button/Remove.vue'
import OutgoingInvoiceCard from '../card/OutgoingInvoice2'
import ModalPayment from '../prompt/Payment2'
import ViewOutgoingInvoice from '../views/Invoice'
import ModalOutgoingInvoice from '../prompt/OutgoingInvoice'

import i18n from '../../libs/i18n'
import useAPI from '../../utils/useAPI'
import Ripple from 'vue-ripple-directive'
import store from '../../store'
import moment from 'moment'

export default {
  directives: {
    Ripple,
  },
  components: {
    BaseTemplate,
    ButtonView,
    ButtonPayment,
    ButtonDownload,
    ButtonClose,
    ButtonEdit,
    ButtonValidate,
    ButtonArchivate,
    ButtonUnarchivate,
    ButtonDuplicate,
    ButtonRemove,
    OutgoingInvoiceCard,
    ModalPayment,
    ViewOutgoingInvoice,
    ModalOutgoingInvoice
  },
  props: {
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    allowRemove: {
      type: Boolean,
      default: true
    },
    allowPayment: {
      type: Boolean,
      default: false
    },
    disabledFilters: {
      type: Array,
      default: () => []
    },
    displayCustomFields: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const columns = ref([
      {
        display: capitalize(i18n.t('billingDate')),
        key: 'billingDate',
        sortable: true,
        cell: {
          isDate: true
        }
      },
      {
        display: capitalize(i18n.t('billNumber')),
        key: 'billNumber',
        sortable: true
      },
      {
        display: capitalize(i18n.t('status')),
        key: 'status',
        sortable: false,
        cell: {
          useIcon: true,
          center: true,
          type: 'customerStatus'
        }
      },
      {
        display: capitalize(i18n.t('contact')),
        key: 'contact',
        sortable: false,
        cell: {
          isContact: true
        }
      },
      {
        display: capitalize(i18n.t('title')),
        key: 'title',
        sortable: false,
        cell: {}
      },
      {
        display: capitalize(i18n.t('totalAmount')),
        key: 'totalAmount',
        sortable: true,
        cell: {
          isCurrency: true
        }
      },

    ])
    const filters = ref([
      {
        text: capitalize(i18n.tc('currentFiscalYear')),
        key: '_filter_isCurrentFiscalYear',
        searchCriteria: [{
          column_name: 'billingDate',
          comparator: 'range',
          search_text_0: '2022-07-01',
          search_text_1: '2023-06-30'
        }],
        // selected: true,
      },
      {
        text: capitalize(i18n.tc('company')),
        key: '_filter_isCustomerCompany',
        searchCriteria: [{
          column_name: 'customerCompany',
          comparator: 'notNull',
        }],
      },
      {
        text: capitalize(i18n.tc('individual')),
        key: '_filter_isCustomerIndividual',
        searchCriteria: [{
          column_name: 'customerIndividual',
          comparator: 'notNull',
        }],
      },
      {
        text: capitalize(i18n.tc('myOutgoingInvoices')),
        key: '_filter_myOutgoingInvoices',
        searchCriteria: [{
          column_name: 'createdBy',
          comparator: 'me',
        }],
      },
      {
        text: capitalize(i18n.tc('draft')),
        key: '_filter_draft',
        searchCriteria: [{
          column_name: 'billNumber',
          comparator: 'null',
        }],
      },
      {
        text: capitalize(i18n.tc('sent')),
        key: '_filter_sent',
        searchCriteria: [{
          column_name: 'billNumber',
          comparator: 'notNull',
        }],
      },
      {
        text: capitalize(i18n.tc('active')),
        key: '_filter_unarchivated',
        searchCriteria: [{
          column_name: 'isArchived',
          comparator: 'false',
        }],
      },
      {
        text: capitalize(i18n.tc('archived')),
        key: '_filter_unarchivated',
        searchCriteria: [{
          column_name: 'isArchived',
          comparator: 'true',
        }],
      },

    ])
    const openViewWindow = ref(false)
    const selectedItems = ref([])
    const currentPayment = ref({})
    const paymentModalShow = ref(false)
    const maxPaymentAmount = ref(null)
    const customerInvoiceModalShow = ref(false)
    const currentCustomerInvoice = ref({})

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { outgoingInvoices2, outgoingInvoicesContext, autocompleteBankAccounts } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { submitValidatedPayment } = usePayments()

    const {
      submitValidatedOutgoingInvoice,
      removeOutgoingInvoice,
      validateOutgoingInvoice,
      downloadOutgoingInvoice
    } = useCustomerInvoices()

    const showDetails = (outgoingInvoice) => {
      if (outgoingInvoice != null) {
        store.dispatch('outgoingInvoice2/getOutgoingInvoice', outgoingInvoice.id)
            .then(response => {
              selectedItems.value = [outgoingInvoice]
              selectedItems.value[0] = response
              openViewWindow.value = true
            })

      } else {
        selectedItems.value = []
        openViewWindow.value = false
      }
    }

    const duplicateCustomerInvoice = (customerInvoice) => {
      store.dispatch('outgoingInvoice2/getOutgoingInvoice', customerInvoice.id)
          .then(customerInvoice => {
            currentCustomerInvoice.value = customerInvoice
            // populateWorkflow(currentCustomerInvoice.value)
            delete currentCustomerInvoice.value.billNumber
            delete currentCustomerInvoice.value.customerPurchaseOrderNumber
            delete currentCustomerInvoice.value.customerPurchaseOrderDate
            delete currentCustomerInvoice.value.customerPurchaseOrder
            delete currentCustomerInvoice.value.id
            delete currentCustomerInvoice.value.logs

            customerInvoiceModalShow.value = true
          })
    }

    const addPayment = (outgoingInvoices) => {
      if (outgoingInvoices.length) {
        let amount = 0
        let paymentRelations = []
        outgoingInvoices.forEach(oi => {
          amount += parseFloat(oi._balance)
          paymentRelations.push({
            amount: parseFloat(oi._balance),
            outgoingInvoice: oi
          })
        })

        currentPayment.value = {
          paymentMethod: { id: 1, name: i18n.t('Bank transfert') },
          date: (new Date()).toISOString().slice(0, 10),
          paymentRelations: paymentRelations,
          bankAccount: autocompleteBankAccounts.value.find(ba => ba.id == 1)
        }

        maxPaymentAmount.value = JSON.parse(JSON.stringify(amount))

        paymentModalShow.value = true
      }
    }

    const submitValidatedPaymentLocal = () => {
      submitValidatedPayment(currentPayment.value)
          .then(response => {
            currentPayment.value = {}
            selectedItems.value = []
            maxPaymentAmount.value = null
            openViewWindow.value = false
            paymentModalShow.value = false
          })
    }

    const archivateOutgoingInvoiceLocal = (customerInvoice, isArchived) => {
      store.dispatch('outgoingInvoice2/getOutgoingInvoices', customerInvoice.id)
          .then(customerInvoice => {
            currentCustomerInvoice.value = customerInvoice
            // populateWorkflow(currentCustomerInvoice.value)
            currentCustomerInvoice.value.isArchived = isArchived

            submitValidatedOutgoingInvoice(currentCustomerInvoice.value)
                .then(response => {
                  // updateWorkflow(response.data)

                  resetCustomerInvoice()
                })
          })
    }

    const resetCustomerInvoice = () => {
      currentCustomerInvoice.value = JSON.parse(JSON.stringify(store.getters['outgoingInvoice2/getEmptyOutgoingInvoice']))
    }

    const removeCustomerInvoiceLocal = (customerInvoice) => {
      removeOutgoingInvoice(customerInvoice)
          .then(response => {

          })
    }

    const submitValidatedCustomerInvoiceLocal = () => {
      submitValidatedOutgoingInvoice(currentCustomerInvoice.value)
          .then(response => {
            // updateWorkflow(response.data)
            customerInvoiceModalShow.value = false

            // if (getRoute().name == 'Workflow view') {
            //   let workflow = store.getters['workflow/getWorkflow'](getRoute().params.workflowId)
            //   response.data.workflow = response.data.workflow.id
            //
            //   if ('id' in currentOutgoingInvoice.value) {
            //     let index = workflow.outgoingInvoices.findIndex(i => i.id == currentOutgoingInvoice.value.id)
            //     workflow.outgoingInvoices.splice(index, 1, JSON.parse(JSON.stringify(response.data)))
            //   } else {
            //     workflow.outgoingInvoices.push(response.data)
            //   }
            //
            // }
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetCustomerInvoice()

    if (props.disabledFilters.length) {
      props.disabledFilters.forEach(df => {
        filters.value.splice(filters.value.findIndex(f => f.key == df), 1)
      })
    }

    return {
      // Components
      capitalize,
      currency,

      // Data
      columns,
      filters,
      openViewWindow,
      selectedItems,
      currentPayment,
      paymentModalShow,
      maxPaymentAmount,
      currentCustomerInvoice,
      customerInvoiceModalShow,

      // Computed
      outgoingInvoices2,
      outgoingInvoicesContext,

      // Methods
      showDetails,
      downloadOutgoingInvoice,
      duplicateCustomerInvoice,
      validateOutgoingInvoice,
      addPayment,
      submitValidatedPaymentLocal,
      archivateOutgoingInvoiceLocal,
      removeCustomerInvoiceLocal,
      submitValidatedCustomerInvoiceLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    deleteCustomerInvoiceAlert (customerInvoice) {
      this.$bvModal
          .msgBoxConfirm(this.$t('DeleteAlert', { msg: this.$t('theInvoice') }), {
            okVariant: 'danger',
            okTitle: this.capitalize(this.$t('delete')),
            cancelVariant: 'outline-secondary',
            cancelTitle: this.capitalize(this.$t('cancel')),
            centered: true,
          })
          .then(value => {
            if (value == true) {
              this.removeCustomerInvoiceLocal(customerInvoice)
            }
          })
    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>