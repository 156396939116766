var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{staticClass:"w-100",attrs:{"label-for":_vm.name+ (_vm.listIndex != -1?('_'+(_vm.listIndex+1)):''),"label":_vm.displayLabel?_vm.capitalize(_vm.$tc(_vm.name)):null}},[(_vm.isRequired)?_c('validation-provider',{attrs:{"name":_vm.$tc(_vm.name)+ (_vm.listIndex != -1?(' '+(_vm.listIndex+1)):''),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-100",attrs:{"id":_vm.name+ (_vm.listIndex != -1?('_'+(_vm.listIndex+1)):''),"multiple":_vm.multiple,"label":_vm.label,"options":_vm.options,"placeholder":_vm.placeholder!=''?_vm.placeholder:(_vm.$t('Choose a ') + _vm.$tc(_vm.name).toLowerCase()),"selectable":function (option) { return !option.disabled; },"clearable":_vm.clearable,"disabled":_vm.disabled,"append-to-body":_vm.appendToBody,"getOptionKey":_vm.getOptionKey},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._t("customList",[(option[_vm.reduce] == '-')?_c('span',[_c('b-dropdown-divider')],1):(_vm.label != 'label')?_c('span',[_vm._v(_vm._s(option[_vm.label]))]):_c('span',[_vm._v(_vm._s(option[_vm.name]))])],{"option":option})]}},(_vm.allowAdd)?{key:"list-header",fn:function(){return [_vm._t("list-header",[_c('li',{staticClass:"add-new-client-header d-flex align-items-center my-50 cursor-pointer",on:{"click":function($event){return _vm.$emit('addCallback')}}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v(_vm._s(_vm.$t(_vm.addText)))])],1)])]},proxy:true}:null,{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" "+_vm._s(_vm.$t('NoResult'))+" ")]}}],null,true),model:{value:(_vm.localModel),callback:function ($$v) {_vm.localModel=$$v},expression:"localModel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4053376642)}):_c('v-select',{staticClass:"w-100",attrs:{"id":_vm.name+ (_vm.listIndex != -1?('_'+(_vm.listIndex+1)):''),"multiple":_vm.multiple,"label":_vm.label,"options":_vm.options,"placeholder":_vm.placeholder!=''?_vm.placeholder:(_vm.$t('Choose a ') + _vm.$tc(_vm.name).toLowerCase()),"selectable":function (option) { return !option.disabled; },"clearable":_vm.clearable,"disabled":_vm.disabled,"append-to-body":_vm.appendToBody,"getOptionKey":_vm.getOptionKey},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_vm._t("customList",[(option[_vm.reduce] == '-')?_c('span',[_c('b-dropdown-divider')],1):(_vm.label != 'label')?_c('span',[_vm._v(_vm._s(option[_vm.label]))]):_c('span',[_vm._v(_vm._s(option[_vm.name]))])],{"option":option})]}},(_vm.allowAdd)?{key:"list-header",fn:function(){return [_vm._t("list-header",[_c('li',{staticClass:"add-new-client-header d-flex align-items-center my-50 cursor-pointer",on:{"click":function($event){return _vm.$emit('addCallback')}}},[_c('icon',{attrs:{"icon":"plus"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v(_vm._s(_vm.$t(_vm.addText)))])],1)])]},proxy:true}:null,{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" "+_vm._s(_vm.$t('NoResult'))+" ")]}}],null,true),model:{value:(_vm.localModel),callback:function ($$v) {_vm.localModel=$$v},expression:"localModel"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }