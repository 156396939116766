<template>
  <div class="row">
    <div class="col-md-3">
      <div
          v-for="(item, index) in items"
          :key="index"
      >
        <slot name="leftViewWindow" :item="item" />
      </div>
    </div>
    <div class="col-md-9">
      <b-card>
        <slot name="windowView"/>
      </b-card>

    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {},
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>