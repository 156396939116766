<template>
  <div>
    <user-form :user="individual"/>

    <b-row class="mt-2">
      <b-col cols="12">
        <b-tabs fill>
          <!--          Tab addresses-->
          <b-tab
              active
              :title="capitalize($tc('address', 2)) + '(' + individual.addresses.length + ')'"
          >
<!--            <template #title>-->
<!--              <icon icon="map-marked-alt"/>-->
<!--              <span>{{ capitalize($tc('address', 2)) }} ({{ individual.addresses.length }})</span>-->
<!--            </template>-->

            <tab-addresses
                :addresses="individual.addresses"
                :displaySiren="false"
            />
          </b-tab>

        </b-tabs>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'
import UserForm from '@/components/form/User'
import TabAddresses from '@/components/form/company/components/_tab_addresses'

export default {
  components: {
    UserForm,
    TabAddresses
  },
  props: {
    individual: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>