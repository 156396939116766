<template>
  <!--  <div>-->
  <b-row>
    <b-col cols="12">

      <!--        Header-->
      <b-card no-body>
        <b-card-body>
          <b-row>
            <!--      Left-->
            <b-col md="8">
              <contact-component
                  :outgoing-invoice="invoice"
                  @updateVatAllowed="updateVatAllowed"
              />

              <!--              <b-row>-->
              <!--                &lt;!&ndash; Contact &ndash;&gt;-->
              <!--                <b-col cols="12">-->
              <!--                  <field-contact-->
              <!--                      :contactInit="selectedContact"-->
              <!--                      :customerCompany.sync="invoice.customerCompany"-->
              <!--                      :customerIndividual.sync="invoice.customerIndividual"-->
              <!--                      :isRequired="true"-->
              <!--                      @updateAddresses="updateAddresses"-->
              <!--                  />-->
              <!--                </b-col>-->

              <!--                &lt;!&ndash; Billing address &ndash;&gt;-->
              <!--                <b-col-->
              <!--                    md="6"-->
              <!--                    :key="componentKey">-->

              <!--                  <field-select-->
              <!--                      v-if="invoice.customerCompany != null || invoice.customerIndividual != null"-->
              <!--                      :model.sync="invoice.billingAddress"-->
              <!--                      :name="'billingAddress'"-->
              <!--                      :options="addresses"-->
              <!--                      :label="'_display'"-->
              <!--                      :isRequired="true"-->
              <!--                  />-->
              <!--                </b-col>-->

              <!--                &lt;!&ndash; Delivery address &ndash;&gt;-->
              <!--                <b-col md="6">-->
              <!--                  <field-select-->
              <!--                      v-if="invoice.customerCompany != null || invoice.customerIndividual != null"-->
              <!--                      :model.sync="invoice.deliveryAddress"-->
              <!--                      :name="'deliveryAddress'"-->
              <!--                      :options="addresses"-->
              <!--                      :label="'_display'"-->
              <!--                      :isRequired="true"-->
              <!--                  />-->
              <!--                </b-col>-->

              <!--              </b-row>-->
            </b-col>

            <!-- Right -->
            <b-col md="4">
              <div class="w-100 mx-auto"
                   style="max-width: 12rem">

                <!--            Pre tax amount-->
                <div class="d-flex align-content-center justify-content-between">
                  <p>
                    {{ $t('PreTaxAmount:') }}
                  </p>
                  <p>
                    <b>{{ currency(invoice.preTaxAmount) }}</b>
                  </p>
                </div>
                <!--            <VAT-->
                <div class="d-flex align-content-center justify-content-between">
                  <p>
                    {{ $t('VAT:') }}
                  </p>
                  <p>
                    <b>{{ currency(invoice.vat) }}</b>
                  </p>
                </div>
                <hr class="my-50">

                <!--            Total amount-->
                <div class="d-flex align-content-center justify-content-between">
                  <p>
                    {{ $t('TotalAmount:') }}
                  </p>
                  <p>
                    <b>{{ currency(invoice.totalAmount) }}</b>
                  </p>
                </div>

              </div>
            </b-col>

            <!-- Title -->
            <b-col
                :md="invoice._linkedOffer == null?9:7"
            >
              <field-input
                  :name="'title'"
                  :model.sync="invoice.title"
              />
            </b-col>

            <!-- Is order -->
            <b-col
                :md="invoice._linkedOffer == null?3:5"
                   :key="componentKey">
              <field-switch
                  v-if="invoice._linkedOffer == null"
                  :model.sync="displayPurchaseOrder"
                  :name="'purchaseOrder'"
              />


              <b-form-group
                  v-else
                  :label-for="'xxxx'"
                  :label="capitalize($t('purchaseOrder'))"
              >

              <card-document
                  id="xxxx"
                  :document="{extension: 'pdf', name: invoice._linkedOffer.quotes.slice(-1)[0].name }"
                  :isDeletable="false"
                  :isLinkable="true"
                  @click="downloadOffer(invoice)"
                  @download="downloadOffer(invoice)"
                  @toRoute="$router.push({name: 'Offer view', params: {'offerId': invoice._linkedOffer.id}})"
              />
              </b-form-group>
            </b-col>

          </b-row>
        </b-card-body>
      </b-card>

      <!-- Tabs -->
      <b-card no-body>
        <b-card-body class="pt-0">
          <b-tabs>

            <!-- Products -->
            <b-tab
                style="max-height: 468px; overflow-x: hidden; overflow-y: auto"
                active
                :title=" capitalize($tc('product', 2)) + ' (' + invoice.products.length + ')'"
                :title-link-class="{'text-danger font-weight-bolder': isProductsTabError}"
            >
              <!--              <b-card-text>-->
              <tab-products
                  :outgoing-invoice="invoice"
                  :disableTVA="!isVatAllowed"
                  @changePreTaxAmount="changePreTaxAmount"
              />
              <!--              </b-card-text>-->
            </b-tab>

            <!-- Terms a conditions -->
            <b-tab
                :title="capitalize($t('terms&conditions'))"
                :title-link-class="{'text-danger font-weight-bolder': isTermsTabError}"
            >
              <!--              <b-card-text>-->
              <tab-terms
                  :outgoing-invoice="invoice"
                  @changePreTaxAmount="changePreTaxAmount"
              />
              <!--              </b-card-text>-->

            </b-tab>

            <!-- Workflow & phase -->
            <b-tab :title="capitalize($t('workflow'))">
              <!--              <b-card-text>-->
              <tab-workflow :outgoing-invoice="invoice"/>
              <!--              </b-card-text>-->
            </b-tab>

            <!-- Order -->
            <b-tab
                v-if="displayPurchaseOrder"
                :title="capitalize($tc('order'))"
                :title-link-class="{'text-danger': isOrderTabError}"
            >
              <!--              <b-card-text>-->
              <tab-order :outgoing-invoice="invoice"/>
              <!--              </b-card-text>-->
            </b-tab>

            <!-- Specific mentions -->
            <b-tab :title="capitalize($t('specificMentions'))">
              <!--              <b-card-text>-->
              <tab-specific-mentions :outgoing-invoice="invoice"/>
              <!--              </b-card-text>-->
            </b-tab>


          </b-tabs>
        </b-card-body>

      </b-card>
    </b-col>
  </b-row>
  <!--  </div>-->
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { capitalize, currency, percent } from '@/utils/filter'
import { getRoute } from '@/utils/utils'

import FieldDate from '@/components/input/Date'
import FieldFile from '@/components/input/File'
import FieldInput from '@/components/input/Input'
import FieldSelect from '@/components/input/Select'
import FieldContact from '@/components/input/Contact'
import FieldDiscount from '@/components/input/Discount'
import FieldSwitch from '../../input/Switch'
import FormProducts from '@/components/form/products/Products'
import store from '@/store'
import useAPI from '@/utils/useAPI'

import ContactComponent from './components/_contact'
import TabProducts from './components/_tabProducts'
import TabTerms from './components/_tabTerms&conditions'
import TabWorkflow from './components/_tabWorkflow'
import TabOrder from './components/_tabOrder'
import TabSpecificMentions from './components/_tabSpecificMentions'
import CardDocument from '../../card/Document'
import { isObject } from '../../../utils/utils'

export default {
  components: {
    FieldDate,
    FieldInput,
    FieldSelect,
    FieldContact,
    FieldDiscount,
    FieldSwitch,
    FormProducts,
    FieldFile,
    TabProducts,
    TabTerms,
    TabWorkflow,
    TabOrder,
    TabSpecificMentions,
    ContactComponent,
    CardDocument
  },
  props: {
    invoice: {
      type: Object,
      default: () => {}
    },
    formErrors: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // console.log(props.invoice)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)
    const displayPurchaseOrder = ref(false)
    const displayWorkflow = ref(false)
    const addresses = ref([])
    const isProductsTabError = ref(false)
    const isTermsTabError = ref(false)
    const isOrderTabError = ref(false)
    const isVatAllowed = ref(true)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { myCompany, paymentMethods, paymentTerms, workflows } = useAPI()

    // const selectedContact = computed(() => {
    //   if (props.invoice.customerCompany) {
    //     if (!('addresses' in props.invoice.customerCompany)) {
    //       store.dispatch('company/getCompany', props.invoice.customerCompany.id)
    //           .then(company => {
    //             props.invoice.customerCompany = company
    //             return company
    //           })
    //     } else {
    //       return props.invoice.customerCompany
    //     }
    //   } else if (props.invoice.customerIndividual) {
    //     return props.invoice.customerIndividual
    //   } else {
    //     return null
    //   }
    // })

    const productsSectionMaxHeight = computed(() => {
      if (displayWorkflow.value) {
        let output = 275
        // if (selectedContact.value != null) {
        //   output -= 70
        // }
        if (displayPurchaseOrder.value) {
          output -= 60
        }

        return output
      } else {
        let output = 275
        if (displayPurchaseOrder.value) {
          output -= 85
        }

        return output
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(selectedContact, (val) => {
    //   if (val) {
    //     // addresses.value = val.addresses
    //     // if (val.company && 'addresses' in val.company) {
    //     //   addresses.value = addresses.value.concat(val.company.addresses)
    //     // }
    //
    //     if (val.addresses.length >= 1) {
    //       // let selectedAddress = val.addresses[0]
    //       //
    //       // props.invoice.billingAddress = selectedAddress.id
    //       // props.invoice.deliveryAddress = selectedAddress.id
    //
    //       if (!['FR', 'RE'].includes(selectedAddress.country)) {
    //         isVatAllowed.value = false
    //         changePreTaxAmount()
    //       }
    //
    //       // props.invoice.billingAddress = val.addresses[0].id
    //       // props.invoice.deliveryAddress = val.addresses[0].id
    //     }
    //   // } else {
    //     // addresses.value = []
    //   }
    // })

    // watch(() => props.invoice, val => {
    //   console.log(val)
    // })

    // watch(() => props.invoice, val => {
    //   updateVatAllowed()
    // })

    watch(() => props.invoice.billingAddress, val => {
      updateVatAllowed()
      // if (
      //     val != null &&
      //     props.invoice.customerCompany != null &&
      //     ('addresses' in props.invoice.customerCompany)) {
      //   let addressObject = props.invoice.customerCompany.addresses.find(ad => ad.id == val)
      //
      //   if (!['FR', 'RE'].includes(addressObject.country)) {
      //     isVatAllowed.value = false
      //     changePreTaxAmount()
      //   } else {
      //     isVatAllowed.value = true
      //     changePreTaxAmount()
      //   }
      // }
    })

    watch(displayPurchaseOrder, (val) => {
      if (val == false) {
        props.invoice.customerPurchaseOrderNumber = null
        props.invoice.customerPurchaseOrderDate = null
        props.invoice.customerPurchaseOrder = null
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const updateVatAllowed = () => {
      if (
          props.invoice.customerCompany != null &&
          ('addresses' in props.invoice.customerCompany)) {
        let addressObject = props.invoice.customerCompany.addresses.find(ad => ad.id == props.invoice.billingAddress)

        if (!['FR', 'RE'].includes(addressObject.country)) {
          // isVatAllowed.value = false
          //
          // props.invoice.products.forEach(p => {
          //   p.vat = 0
          //   p.totalAmount = p.preTaxAmount
          // })

          changePreTaxAmount()
        } else {
          isVatAllowed.value = true


          // props.invoice.products.forEach(p => {
          //   let vatRateId = isObject(p.vatRate)?p.vatRate.id:p.vatRate
          //   p.vat = store.getters['vat/getVat'](vatRateId).rate * p.preTaxAmount
          //   p.totalAmount = parseFloat(p.preTaxAmount) + parseFloat(p.vat)
          // })

          changePreTaxAmount()

        }
      }
    }

    const changePreTaxAmount = (input = null, value = null) => {
      let subPreTaxAmount = 0
      props.invoice.preTaxAmount = 0
      props.invoice.vat = 0
      props.invoice.totalAmount = 0

      props.invoice.products.forEach(product => {
        // console.log(product)
        subPreTaxAmount += parseFloat(product.preTaxAmount)

        if (isVatAllowed.value) {
          props.invoice.vat += parseFloat(product.vat)
        }
      })

      let discount = parseFloat(props.invoice.discount ? props.invoice.discount : 0)
      let isDiscountPercent = props.invoice.isDiscountPercent
      if (input == 'discount') {
        if (typeof value === 'boolean') {
          isDiscountPercent = value
        } else {
          discount = parseFloat(value ? value : 0)
        }
      }

      if (discount != 0) {
        if (isDiscountPercent) {
          props.invoice.preTaxAmount = parseFloat(
              subPreTaxAmount * (1 - discount / 100)
          ).toFixed(2)

          props.invoice.vat = parseFloat(
              props.invoice.vat * (1 - discount / 100)
          ).toFixed(2)

        } else {
          props.invoice.preTaxAmount = subPreTaxAmount - discount
          props.invoice.vat = 0

          let discountPercent = discount / subPreTaxAmount

          props.invoice.products.forEach(product => {
            let discountedPreTaxAmount = parseFloat(product.preTaxAmount) * (1 - discountPercent)
            let rate = store.getters['vat/getVat'](product.vatRate).rate

            props.invoice.vat += discountedPreTaxAmount * rate
          })

        }
      } else {
        props.invoice.preTaxAmount = subPreTaxAmount
      }

      // if (props.invoice.preTaxAmount < 0) {
      //   props.invoice.preTaxAmount = 0
      //   props.invoice.vat = 0
      // }

      // let duty = parseFloat(props.invoice.duty ? props.invoice.duty : 0)
      // props.invoice.preTaxAmount += duty
      // props.invoice.vat += parseFloat(duty * 0.2).toFixed(2)

      let tax = parseFloat(props.invoice.tax ? props.invoice.tax : 0)

      // if (props.invoice.billingAddress != null && props.invoice.billingAddress.country != "FR") {
      //   props.invoice.vat = 0
      // }

      props.invoice.totalAmount =
          parseFloat(props.invoice.preTaxAmount) +
          parseFloat(props.invoice.vat) +
          tax

      componentKey.value++
    }

    const updateAddresses = () => {
      // console.log(props.invoice)
      if (props.invoice.customerCompany != null) {
        if (
            ('addresses' in props.invoice.customerCompany) &&
            props.invoice.customerCompany.addresses.length > 0
        ) {
          addresses.value = props.invoice.customerCompany.addresses
          props.invoice.billingAddress = props.invoice.customerCompany.addresses[0].id
          props.invoice.deliveryAddress = props.invoice.customerCompany.addresses[0].id

        } else {
          addresses.value = []
        }

      } else if (props.invoice.customerIndividual != null) {
        addresses.value = props.invoice.customerIndividual.addresses
        if (props.invoice.customerIndividual.company && 'addresses' in props.invoice.customerIndividual.company) {
          addresses.value = addresses.value.concat(props.invoice.customerIndividual.company.addresses)
        }

        if (props.invoice.customerIndividual.addresses.length > 0) {
          props.invoice.billingAddress = props.invoice.customerIndividual.addresses[props.invoice.customerCompany.addresses.length - 1].id
          props.invoice.deliveryAddress = props.invoice.customerIndividual.addresses[props.invoice.customerCompany.addresses.length - 1].id
        }
      } else {
        addresses.value = []
      }

      componentKey.value++
    }

    const downloadOffer = (outgoingInvoice) => {
      let link = document.createElement('a')
      link.setAttribute('target', '_blank')
      link.href = outgoingInvoice._linkedOffer.quotes.slice(-1)[0].url
      link.click()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    onMounted(() => {
      if (
          props.invoice.customerPurchaseOrderNumber != null ||
          props.invoice.customerPurchaseOrderDate != null ||
          props.invoice.customerPurchaseOrder != null
      ) {
        displayPurchaseOrder.value = true
      }
    })

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (getRoute().name == 'OutgoingInvoices') displayWorkflow.value = true

    return {
      // Components
      capitalize,
      currency,
      percent,

      // Data
      componentKey,
      displayPurchaseOrder,
      displayWorkflow,
      addresses,
      isProductsTabError,
      isTermsTabError,
      isOrderTabError,
      isVatAllowed,

      // Computed
      myCompany,
      paymentMethods,
      paymentTerms,
      // selectedContact,
      workflows,
      productsSectionMaxHeight,

      // Methods
      updateVatAllowed,
      changePreTaxAmount,
      updateAddresses,
      downloadOffer,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    formErrors: function (val) {
      this.isProductsTabError = false
      this.isTermsTabError = false
      this.isOrderTabError = false

      for (const [field, errorArrray] of Object.entries(val)) {
        // console.log(field, errorArrray);

        if (
            errorArrray.length > 0 &&
            (
                field.startsWith('prix unitaire') || field.startsWith('unit price') ||
                field.startsWith('quantité') || field.startsWith('quantity')
            )
        ) {
          this.isProductsTabError = true
        }

        if (
            (
                (field == 'conditions de paiement' || field == 'payment terms') ||
                (field == 'moyen de paiement' || field == 'payment method')
            ) &&
            errorArrray.length > 0
        ) {
          this.isTermsTabError = true
        }

        if (
            (field == 'date de signature' || field == 'signature date') &&
            errorArrray.length > 0) {
          this.isOrderTabError = true
        }

      }
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/base/pages/invoice.scss";
@import '~@/assets/scss/vue/libs/vue-select.scss';

.vs__dropdown-menu {
  overflow: visible;
}
</style>