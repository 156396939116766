<template>
  <div>

    <b-row>
      <transition name="slide-fade">
        <b-col
            v-if="existingCompany"
            cols="12"
        >
          <b-alert
              show
              variant="danger"
          >
            <div class="alert-body">
              <icon icon="exclamation-triangle"/>
              <span class="ml-25">{{ $t('This company already exists') }}</span>
              <dl class="row mb-0">
                <dt class="col-sm-3 text-right">
                  {{ capitalize($t('name')) }}
                </dt>
                <dd class="col-sm-9">
                  {{ existingCompany.name }}
                </dd>
              </dl>
              <dl class="row mb-0">
                <dt class="col-sm-3 text-right">
                  {{ $t('siren') }}
                </dt>
                <dd class="col-sm-9">
                  {{ existingCompany.siren == null ? '-' : existingCompany.siren }}
                </dd>
              </dl>
            </div>
          </b-alert>
        </b-col>
      </transition>

      <!--          Name-->
      <b-col md="12">
        <field
            :name="'name'"
            :rules="'required'"
            :model.sync="company.name"
        />
      </b-col>

      <!--          Phone-->
      <b-col md="6">
        <field
            :name="'phone'"
            :rules="'phone'"
            :model.sync="company.phone"
        />
      </b-col>

      <!--          SIREN-->
      <b-col md="6">
        <field
            :name="'siren'"
            :model.sync="company.siren"
        />
      </b-col>

      <!--          VAT number-->
      <b-col md="6">
        <field
            :name="'vatNumber'"
            :model.sync="company.vat"
        />
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col md="12">
        <b-tabs fill>

          <!--          Tab employees-->
          <b-tab
              active
              :title="capitalize($tc('employee', 2)) + ' (' + company.employees.length+')'"
          >
<!--            <template #title>-->
<!--              <icon icon="users"/>-->
<!--              <span>{{ capitalize($tc('employee', 2)) }} ({{ company.employees.length }})</span>-->
<!--            </template>-->

            <company-employees
                :employees="company.employees"
            />
          </b-tab>

          <!--          Tab addresses-->
          <b-tab
              :title="capitalize($tc('address', 2)) + ' ('+ company.addresses.length +')'"
          >
<!--            <template #title>-->
<!--              <icon icon="map-marked-alt"/>-->
<!--              <span>{{ capitalize($tc('address', 2)) }} ({{ company.addresses.length }})</span>-->
<!--            </template>-->

            <tab-addresses
                :addresses="company.addresses"
                :siren="company.siren"
            />
          </b-tab>

        </b-tabs>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { required } from '@/utils/validations/validations'
import { capitalize } from '@/utils/filter'

import useAPI from '../../../utils/useAPI'
import Field from '@/components/input/Input'
import FieldSwitch from '@/components/input/Switch'
import CompanyEmployees from './components/_tab_employees'
import TabAddresses from './components/_tab_addresses'

export default {
  components: {
    Field,
    FieldSwitch,
    ValidationProvider,
    CompanyEmployees,
    TabAddresses
  },
  props: {
    company: {
      type: Object,
      default: () => {}
    },
    isContactEdit: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const initialCompany = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { companies } = useAPI()

    const existingCompany = computed(() => {
      if (props.isContactEdit) {
        if (props.company.name != null && props.company.siren != null) {
          return companies.value.find(
              c =>
                  (
                      c.name == props.company.name ||
                      c.siren == props.company.siren
                  ) &&
                  c.id != initialCompany.value.id
          )
        } else if (props.company.name != null) {
          return companies.value.find(
              c =>
                  c.name == props.company.name &&
                  c.id != initialCompany.value.id
          )
        } else if (props.company.siren != null) {
          return companies.value.find(c =>
              c.siren == props.company.siren &&
              c.id != initialCompany.value.id)
        } else {
          return undefined
        }
      } else {
        if (props.company.name != null && props.company.siren != null) {
          return companies.value.find(c => c.name == props.company.name || c.siren == props.company.siren)
        } else if (props.company.name != null) {
          return companies.value.find(c => c.name == props.company.name)
        } else if (props.company.siren != null) {
          return companies.value.find(c => c.siren == props.company.siren)
        } else {
          return undefined
        }
      }

// return companies.value.find(c => c.name == props.company.name || c.siren == props.company.siren)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    initialCompany.value = JSON.parse(JSON.stringify(props.company))

    return {
      capitalize,
      required,

      existingCompany
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>