<template>
  <field-select
      :model.sync="localModel"
      :name="name"
      :placeholder="$t('Choose an ') + $tc('employee',1)"
      :options="employees"
      :label="'_display'"
      :clearable="clearable"
      :isRequired="isRequired"
      :multiple="multiple"
      :disabled="disabled"
  />
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import FieldSelect from './Select'
import store from '@/store'

export default {
  components: {
    FieldSelect
  },
  props: {
    model: {
      required: true
    },
    name: {
      type: String,
      default: 'employee'
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
    clearable: {
      type: Boolean,
      efault: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    withMe: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(props.model)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const employees = computed(() => {
      if (!props.withMe) {
        return store.getters['user/getEmployeesWithoutMe']
      } else {
        return store.getters['user/getEmployees']
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(localModel, (val) => {
      emit('update:model', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      localModel,

      // Computed
      employees,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    model: function (val) {
      this.localModel = val
    }
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>