<template>
  <div>

    <customer-invoices-view
        @reloadCustomerInvoices="fetchOutgoingInvoices2($event)"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import CustomerInvoicesView from '../../../components/views/CustomerInvoicesView.vue'
import useAPI from '../../../utils/useAPI'

export default {
  components: {
    CustomerInvoicesView
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchOutgoingInvoices2,
      fetchAutocompletePaymentMethods,
      fetchAutocompleteBankAccounts,
    } = useAPI()

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchOutgoingInvoices2()
        .then(() => {
          fetchAutocompletePaymentMethods()
          fetchAutocompleteBankAccounts()
        })

    return {
      // Components

      // Data

      // Computed

      // Methods
      fetchOutgoingInvoices2,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>