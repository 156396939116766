<template>
  <div>
    <div class="d-flex justify-content-between">

      <field-select
          v-if="!multiple"
          :name="name"
          :model.sync="contactLocal"
          :options="contactsListLocal"
          :label="'_display'"
          :reduce="'_vSelectIndex'"
          :isRequired="isRequired"
          :allowAdd="true"
          :addText="'AddContact'"
          :listIndex="listIndex"
          @addCallback="selectContact(true)"
          class="w-100"
          :key="componentKey"
      >
      </field-select>

      <field-select
          v-if="multiple"
          :name="name"
          :model.sync="contactsLocal"
          :options="contactsListLocal"
          :label="'_display'"
          :reduce="'_vSelectIndex'"
          :isRequired="isRequired"
          :allowAdd="true"
          :addText="'AddContact'"
          :listIndex="listIndex"
          @addCallback="selectContact(true)"
          class="w-100"
          :key="componentKey"
          :multiple="multiple"
      >
      </field-select>

      <div class="mt-2 ml-1">
        <button-external-link
            :withBorder="false"
            @click="selectContact(false)"
            :disabled="!canEditContact"
        />
      </div>

    </div>

    <b-modal
        v-if="currentContact.companyEntity != null || currentContact.userEntity != null"
        id="1"
        :title="('_vSelectId' in currentContact.companyEntity || '_vSelectId' in currentContact.userEntity)?$t('EditContact'):$t('NewContact')"
        v-model="contactModalShow"
        size="lg"
        scrollable
        :hide-footer="hideFooter"
        :ok-title="capitalize($t('validate'))"
        :cancel-title="capitalize($t('cancel'))"
        @hide="tryCloseContactModal"
        @hidden="handleCancel"
        @ok="handleOk"
    >
      <validation-observer
          ref="observerRef"
          #default="{handleSubmit}">
        <b-form @submit.prevent="handleSubmit(contactFormValidated)">
          <contact-form :contact="currentContact"
                        :isContactEdit="isContactEdit"/>
          <button ref="submitContactFormRef"
                  class="d-none"></button>
        </b-form>
      </validation-observer>
    </b-modal>

  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'
import { ValidationObserver } from 'vee-validate'
import { isObject } from '../../utils/utils'

import ContactForm from '@/components/form/Contact'
import FieldSelect from './Select'
import store from '@/store'
import ButtonExternalLink from '../button/ExternalLink'
import useAPI from '../../utils/useAPI'

export default {
  components: {
    ContactForm,
    ValidationObserver,
    FieldSelect,
    ButtonExternalLink
  },
  props: {
    name: {
      type: String,
      default: 'contact'
    },
    // contactInit: {
    //   type: Object,
    //   default: () => {}
    // },
    customerCompany: {
      type: Object,
      default: () => {}
    },
    customerCompanies: {
      type: Array,
      default: () => []
    },
    customerIndividual: {
      type: Object,
      default: () => {}
    },
    isCompanyOnly: {
      type: Boolean,
      default: false
    },
    isIndividualOnly: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
    withMe: {
      type: Boolean,
      default: false
    },
    fetchContact: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // console.log(props.contactInit)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const contactLocal = ref(null)
    const contactsLocal = ref([])
    const contactModalShow = ref(false)
    const submitContactFormRef = ref(null)
    const observerRef = ref(null)
    const currentContact = ref({})

    const selectedContact = ref(null)
    const selectedContacts = ref([])
    const componentKey = ref(0)
    const isContactEdit = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { contactsWithMainCompany, contactsWithMe, currentBreakPoint } = useAPI()

    const contactsListLocal = computed(() => {

      if (props.isCompanyOnly == true) {
        if (props.withMe) {
          return contactsWithMe.value.filter(ct => !('company' in ct))
        } else {
          return contactsWithMainCompany.value.filter(ct => !('company' in ct))
        }
      } else if (props.isIndividualOnly) {
        if (props.withMe) {
          return contactsWithMe.value.filter(ct => ('company' in ct))
        } else {
          return contactsWithMainCompany.value.filter(ct => ('company' in ct))
        }
      } else {
        if (props.withMe) {
          return contactsWithMe.value
        } else {
          return contactsWithMainCompany.value
        }
      }
    })

    const canEditContact = computed(() => {
      return contactLocal.value != null
    })

    const hideFooter = computed(() => {
      return !['xs', 'sm'].includes(currentBreakPoint.value)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(contactLocal, () => {
      if (contactLocal.value != null) {
        // console.log(contactLocal.value)
        // console.log(contactsListLocal.value)
        // console.log(selectedContact.value)
        let index = contactLocal.value
        // if ('_vSelectIndex' in contactLocal.value) {
        if (isObject(contactLocal.value) && '_vSelectIndex' in contactLocal.value) {
          index = contactLocal.value._vSelectIndex
        }

        selectedContact.value = contactsListLocal.value.find(contact => contact._vSelectIndex == index)

        // console.log(contactsListLocal.value)
        // console.log(selectedContact.value)

        if (
            selectedContact.value != null &&
            '_vSelectId' in selectedContact.value &&
            selectedContact.value._vSelectId != null
        ) {
          selectedContact.value.id = selectedContact.value._vSelectId

          if (selectedContact.value._vSelectType == 'user') {
            store.dispatch('user/getUser', selectedContact.value._vSelectId)
                .then(user => {
                  emit('update:customerCompany', null)
                  emit('update:customerIndividual', user)
                  emit('updated')

                  selectedContact.value = user
                  selectedContact.value._vSelectIndex = contactLocal.value._vSelectIndex
                  selectedContact.value._vSelectType = 'user'
                  selectedContact.value._vSelectId = user.id

                })
          } else {
            if (props.fetchContact) {
              store.dispatch('company/getCompany', selectedContact.value._vSelectId)
                  .then(company => {
                    emit('update:customerCompany', company)
                    emit('update:customerIndividual', null)
                    emit('updated')

                    selectedContact.value = company
                    selectedContact.value._vSelectIndex = contactLocal.value._vSelectIndex
                    selectedContact.value._vSelectType = 'company'
                    selectedContact.value._vSelectId = company.id
                  })
            } else {
              let company = store.getters['company/getCompany'](selectedContact.value._vSelectId)
              emit('update:customerCompany', company)
              emit('update:customerIndividual', null)
              emit('updated')

              selectedContact.value = company
              selectedContact.value._vSelectIndex = contactLocal.value._vSelectIndex
              selectedContact.value._vSelectType = 'company'
              selectedContact.value._vSelectId = company.id
            }
          }
        }

      } else {
        emit('update:customerCompany', null)
        emit('update:customerIndividual', null)
        emit('updated')
      }
    })

    watch(contactsLocal, (val) => {
      // console.log(val)
      selectedContacts.value = []

      val.forEach(c => {
        let currentContact = null
        let index = c

        if (isObject(c) && '_vSelectIndex' in c) {
          index = c._vSelectIndex
        }
        currentContact = contactsListLocal.value.find(contact => contact._vSelectIndex == index)

        // console.log(currentContact)

        if (
            currentContact != null &&
            '_vSelectId' in currentContact &&
            currentContact._vSelectId != null
        ) {
          currentContact.id = currentContact._vSelectId
          if (currentContact._vSelectType == 'user') {

          } else {
            if (props.fetchContact) {
              store.dispatch('company/getCompany', currentContact._vSelectId)
                  .then(company => {
                    emit('update:customerCompany', company)
                    emit('update:customerIndividual', null)
                    emit('updated')

                    currentContact = company
                    currentContact._vSelectIndex = c
                    currentContact._vSelectType = 'company'
                    currentContact._vSelectId = company.id

                    selectedContacts.value.push(currentContact)
                  })
            } else {
              let company = store.getters['company/getCompany'](currentContact._vSelectId)
              // emit('update:customerCompany', company)
              // emit('update:customerIndividual', null)
              // emit('updated')

              currentContact = company
              currentContact._vSelectIndex = c._vSelectIndex
              currentContact._vSelectType = 'company'
              currentContact._vSelectId = company.id

              selectedContacts.value.push(currentContact)
            }
          }

        }

      })

      // console.log(selectedContacts.value)
      emit('update:customerCompanies', selectedContacts.value)
      emit('updated')
    })

    watch(contactModalShow, () => {
      if (contactModalShow.value == false) {
        resetContact()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { getContact } = useAPI()

    const tryCloseContactModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitContactFormRef.value.click()
      }
    }

    const resetContact = () => {
      currentContact.value = JSON.parse(JSON.stringify({
        _isCompany: true,
        companyEntity: store.getters['company/getEmptyCompany'],
        userEntity: store.getters['user/getEmptyUser']
      }))
      isContactEdit.value = false
    }

    const selectContact = (isNew = false) => {
      if (isNew) {
        resetContact()
      } else {
        if (selectedContact.value != null) {
          isContactEdit.value = true
          currentContact.value = {
            _isCompany: isCompany(selectedContact.value),
            companyEntity: isCompany(selectedContact.value) ? selectedContact.value : store.getters['company/getEmptyCompany'],
            userEntity: !isCompany(selectedContact.value) ? selectedContact.value : store.getters['company/getEmptyCompany']
          }
        } else {
          resetContact()
        }
      }
      contactModalShow.value = true
    }

    const isCompany = (contact) => {
      return ('employees' in contact)
    }

    const handleCancel = () => {}

    const updateContact = (company, individual) => {
      // console.log(company)
      if (company != null) {
        let companyData = store.getters['company/getCompany'](company.id)

        let contactSelect = getContact(companyData)

        if (typeof contactSelect != 'undefined') {
          let contact = JSON.parse(JSON.stringify(contactSelect))

          if (
              contactLocal.value == null ||
              contactLocal.value._vSelectId != contact._vSelectId
          ) {
            contactLocal.value = contact
            componentKey.value++
          }
        }
      } else {
        contactLocal.value = null
        componentKey.value++
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------
    onMounted(() => {
      // console.log('customerCompany', props.customerCompany)
      // console.log('customerIndividual', props.customerIndividual)

      if (
          props.customerCompany != null &&
          props.multiple == false
      ) {
        let companyData = store.getters['company/getCompany'](props.customerCompany.id)
        let contactSelect = getContact(companyData)
        // console.log(contactSelect)
        if (typeof contactSelect != 'undefined') {
          let contact = JSON.parse(JSON.stringify(contactSelect))

          contactLocal.value = contact
          componentKey.value++
        }
      }

      if (
          props.multiple == true
      ) {
        props.customerCompanies.forEach(cc => {
          let companyData = store.getters['company/getCompany'](cc.id)
          let contactSelect = getContact(companyData)
          // console.log(contactSelect)
          if (typeof contactSelect != 'undefined') {
            let contact = JSON.parse(JSON.stringify(contactSelect))

            contactsLocal.value.push(contact)
            componentKey.value++
          }
        })
      }
    })
    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    // resetContact()

    return {
      // Components
      capitalize,

      // Data
      contactLocal,
      contactsLocal,
      contactModalShow,
      submitContactFormRef,
      currentContact,
      componentKey,
      observerRef,
      isContactEdit,

      // Computed
      contactsListLocal,
      canEditContact,
      hideFooter,

      // Methods
      tryCloseContactModal,
      handleCancel,
      selectContact,
      updateContact,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {
    async handleOk (bvModalEvent) {
      bvModalEvent.preventDefault()

      const isValid = await this.$refs.observerRef.validate()
      if (isValid) {
        this.contactFormValidated()
      }
    },
    contactFormValidated () {
      // console.log(this.currentContact)

      if (this.currentContact._isCompany) {// Company
        if ('id' in this.currentContact.companyEntity) {
          // Edit
          this.$store.dispatch('company/updateCompany', this.currentContact.companyEntity)
              .then(response => {
                this.$emit('update:customerCompany', response.data)
                this.$emit('updated')
                this.$nextTick(() => this.contactModalShow = false)
              })
              .catch(error => {
                console.log(error)
              })
        } else {
          // New
          this.$store.dispatch('company/addCompany', this.currentContact.companyEntity)
              .then(response => {
                this.contactLocal = (this.contactsListLocal.length - 1)

                this.contactModalShow = false
                this.componentKey++
              })
              .catch(error => {
                console.log(error)
              })
        }
      } else {
        // User
        if ('id' in this.currentContact.userEntity) {
          // Edit
          this.$store.dispatch('user/updateUser', this.currentContact.userEntity)
              .then(response => {
                this.$emit('update:customerIndividual', response.data)
                this.$emit('updated')
                this.$nextTick(() => this.contactModalShow = false)
              })
              .catch(error => {
                console.log(error)
              })
        } else {
          // New
          this.$store.dispatch('user/addUser', this.currentContact.userEntity)
              .then(response => {
                this.contactLocal = (this.contactsListLocal.length - 1)

                this.contactModalShow = false
                this.componentKey++
              })
              .catch(error => {
                console.log(error)
              })
        }
      }

    }
  },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>