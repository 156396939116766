import store from '@/store'

export const usePayments = () => {
  // ======================================================================
  // ==================              METHODS             ==================
  // ======================================================================
  const submitValidatedPayment = (payment) => {
    return new Promise((resolve, reject) => {
      if ('id' in payment) {
        updatePayment(payment)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewPayment(payment)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewPayment = (payment) => {
    return new Promise((resolve, reject) => {
      store.dispatch('payment/addPayment', payment)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updatePayment = (payment) => {
    return new Promise((resolve, reject) => {
      store.dispatch('payment/updatePayment', payment)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removePayment = (payment) => {
    return new Promise((resolve, reject) => {
      store.dispatch('payment/removePayment', payment)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  // ======================================================================
  // ==================               SETUP              ==================
  // ======================================================================
  return {
    submitValidatedPayment,
    removePayment
  }
}