<template>
  <div class="col-md-4">
    <h2 class="content-header-title border-right-0 pr-1 my-1">
      {{ title != '' ? capitalize($t(title)) : capitalize($t($route.name)) }}
    </h2>
    <slot name="bottomTitle" />

    <button-add
        @click="$emit('addItem')"
        v-if="allowAdd"
        :withIcon="true"
        :isLoading="isAddButtonsLoading"
        :text="textButtonAdd"
        :is-disabled="isButtonAddDisabled"
    />

    <button-upload
        @click="$emit('addItemByUpload')"
        v-if="allowAddByUpload"
        :withText="false"
        :withIcon="true"
        class="ml-2 px-1 mt-0 btnUpload"
        :isLoading="isAddButtonsLoading"
    />

    <div v-if="!allowAdd && !allowAddByUpload">
    </div>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import {capitalize} from '../../../utils/filter'

import ButtonAdd from '../../button/Add'
import ButtonUpload from '../../button/Upload'

export default {
  components: {
    ButtonAdd,
    ButtonUpload
  },
  props: {
    title: {
      type: String
    },
    allowAdd: {
      type: Boolean,
      default: true
    },
    allowAddByUpload: {
      type: Boolean,
      default: false
    },
    isAddButtonsLoading: {
      type: Boolean,
      default: false
    },
    isButtonAddDisabled: {
      type: Boolean,
      default: false
    },
    textButtonAdd: {
      type: String,
      default: ''
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>