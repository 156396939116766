import store from '@/store'

export const useCustomerInvoices = () => {
  const submitValidatedOutgoingInvoice = (outgoingInvoice) => {
    return new Promise((resolve, reject) => {
      if ('id' in outgoingInvoice) {
        updateOutgoingInvoice(outgoingInvoice)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewOutgoingInvoice(outgoingInvoice)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewOutgoingInvoice = (outgoingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('outgoingInvoice2/addOutgoingInvoice', outgoingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateOutgoingInvoice = (outgoingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('outgoingInvoice2/updateOutgoingInvoice', outgoingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeOutgoingInvoice = (outgoingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('outgoingInvoice2/removeOutgoingInvoice', outgoingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const validateOutgoingInvoice = (outgoingInvoice) => {
    return new Promise((resolve, reject) => {
      store.dispatch('outgoingInvoice2/validateOutgoingInvoice', outgoingInvoice)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const downloadOutgoingInvoice = (outgoingInvoice) => {
    store.dispatch('outgoingInvoice/printOutgoingInvoice', outgoingInvoice)
      .then(response => {
      })
      .catch(error => {
        console.log(error)
      })
  }

  return {
    submitValidatedOutgoingInvoice,
    removeOutgoingInvoice,
    validateOutgoingInvoice,
    downloadOutgoingInvoice,
  }
}