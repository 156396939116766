<template>
  <div :class="'col-md-'+(readOnly?'12':'8')">
    <div class="row">
      <div class="col-12 d-flex justify-content-between mb-50 mb-md-0 mt-50 mt-md-0">

        <!--              Results number-->
        <div class="my-auto text-nowrap">
          <slot name="resultInfos"
                :items="items"
                :isLoading="isLoading">
            <icon
                v-if="isLoading"
                icon="spinner"
                :spin="true"
                class="text-warning"
            />

            {{ context.total_results }} {{ $tc('resultFound', items.length) }}
          </slot>
        </div>

        <!--              Filters-->
        <div class="w-100 ml-1 my-auto">
          <div class="d-flex">
            <b-dropdown
                variant="outline-primary"
                class="dropdown-icon-wrapper my-auto"
                size="sm"
            >
              <template #button-content>
                <span class="mr-1">{{ capitalize($tc('filter', 2)) }} ({{ selectedFilters.length }})</span>
                <icon icon="filter"/>
              </template>

              <div
                  v-for="(item,index) in listFilters"
                  :key="index"
              >
                <b-dropdown-divider v-if="item.divider"/>

                <b-dropdown-item
                    v-else
                    @mouseover.native="toogleItem(item, false)"
                    @mouseout.native="toogleItem(item, true)"
                    @click.native="$emit('selectFilter', item)"
                >
                  <span>{{ item.text }}
                    <icon :icon="item.showSubMenu?'caret-down':'caret-left'"
                          v-if="item.children"/>
                  </span>
                  <div v-if="item.children"
                       class="sub-menu"
                       v-show="item.showSubMenu">

                    <div v-for="(item2,index2) in item.children"
                         :key="index2">
                      <b-dropdown-divider v-if="item2.divider"/>

                      <b-dropdown-form
                          v-else-if="item2.key == '_date_custom_filter'"
                          class="py-1">

                        <field-date
                            @click.native.stop.prevent
                            @click.native="item.showSubMenu=true"
                            :model.sync="listFilters[index].children[index2].searchCriteria[0].search_text_0"
                            :name="$t('from:')"
                        />
                        <field-date
                            @click.native.stop.prevent
                            @click.native="item.showSubMenu=true"
                            :model.sync="listFilters[index].children[index2].searchCriteria[0].search_text_1"
                            :name="$t('to:')"
                        />

                        <b-button
                            variant="primary"
                            size="sm"
                            @click="$emit('selectFilter', item2)"
                        >
                          {{ $t('validate') }}
                        </b-button>
                      </b-dropdown-form>

                      <b-dropdown-item
                          v-else
                          @click.native="$emit('selectFilter', item2)">
                        {{ item2.text }}
                      </b-dropdown-item>
                    </div>


                  </div>
                </b-dropdown-item>
              </div>

            </b-dropdown>

            <div v-if="displayCustomFields && context.custom_fields"
                 class="d-none d-md-block my-auto ml-1">
              <i v-if="Object.keys(context.custom_fields).includes('preTaxAmounts', 'totalAmounts')">
                {{ currency(context.custom_fields.preTaxAmounts) }} HT /
                {{ currency(context.custom_fields.totalAmounts) }} TTC
              </i>
              <i v-else-if="Object.keys(context.custom_fields).includes('excludingExpensesAndReimbursements')">
                {{ currency(context.custom_fields.excludingExpensesAndReimbursements) }}
                {{ $t('excludingExpensesAndReimbursements') }}
              </i>
              <i v-else-if="Object.keys(context.custom_fields).includes('totalAmounts')">
                {{ currency(context.custom_fields.totalAmounts) }}
              </i>
            </div>
          </div>
        </div>


        <!--Number per pages-->
        <b-dropdown
            class="my-auto d-none d-md-block"
            v-show="selectedView != 'map'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            :text="itemPerPagesDisplayed"
            variant="flat-primary"
            style="height: 37.77px;"
        >
          <b-dropdown-item @click="$emit('update:perPage',10)">
            10
          </b-dropdown-item>
          <b-dropdown-item @click="$emit('update:perPage',25)">
            25
          </b-dropdown-item>
          <b-dropdown-item @click="$emit('update:perPage',50)">
            50
          </b-dropdown-item>
          <b-dropdown-item @click="$emit('update:perPage',100)">
            100
          </b-dropdown-item>
          <b-dropdown-item @click="$emit('update:perPage',200)">
            200
          </b-dropdown-item>
        </b-dropdown>

        <!-- Buttons views  -->
        <b-form-radio-group
            v-if="!readOnly"
            v-model="selectedView"
            class="ml-1 list item-view-radio-group my-auto"
            buttons
            size="sm"
            button-variant="outline-primary"
            @input.native="$emit('update:selectedView', $event.target.value)"
        >
          <b-form-radio
              v-for="option in itemViewOptions"
              :key="option.value"
              :value="option.value"
          >
            <icon
                :icon="option.icon"
            />
          </b-form-radio>
        </b-form-radio-group>


      </div>
      <div class="col-12">
        <div class="d-flex justify-content-between">

          <!--            Filter badges-->
          <div class="d-flex"
               :class="{'mr-1': selectedFilters.length!=0}">
            <b-badge
                v-for="(item,index) in selectedFilters"
                variant="light-primary"
                class="my-auto"
                :class="{'cursor-pointer': !item.disabled}"
                style="border-radius: 3px; font-size: 0.9rem; margin: 5px 2px 2px 5px;"
                @click="item.disabled?false:$emit('selectedFilters', selectedFilters.splice(index,1))"
            >
              {{ item.text }}
              <icon v-if="!item.disabled"
                    icon="times"/>
            </b-badge>
          </div>

          <!--            SearchBar-->
          <b-input-group class="input-group-merge">
            <b-form-input
                :placeholder="$t('Search')"
                class="search-product"
                @input.native="$emit('update:query', $event.target.value)"
            />
            <b-input-group-append is-text>
              <icon icon="search"
                    class="text-muted"/>
            </b-input-group-append>
          </b-input-group>

        </div>

      </div>
    </div>

  </div>
</template>

<script>
import mixinSearchFilter from './mixinSearchFilter'

export default {
  mixins: [mixinSearchFilter]
}
</script>

<style lang="scss">

</style>