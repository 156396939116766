<template>
  <div>

    <b-row>

      <!--      Siren-->
      <b-col
          v-if="!isSimpleAddress && displaySiren"
          md="3"
      >
        <field
            :type="'siret'"
            :name="'siret'"
            :isGroup="true"
            :rules="'nic'"
            :model.sync="address.nic"
        >
          <template v-slot:prepend>
            {{ siren ? siren : '-' }}
          </template>
        </field>
      </b-col>

      <!--      Billing address-->
      <b-col
          v-if="!isSimpleAddress"
          :md="displaySiren?3:6"
          class="d-flex mt-md-2 mb-50 mb-md-0">
        <b-form-checkbox
            v-model="address.isBillingAddress"
            class="custom-control-primary"
            switch
        >
          <span class="switch-icon-left">
            <icon icon="file-invoice-dollar"/>
          </span>
          <span class="switch-icon-right">
            <icon icon="map-marker-alt"/>
          </span>
        </b-form-checkbox>
        <span v-if="address.isBillingAddress">{{ capitalize($tc('billingAddress')) }}</span>
        <span v-else>{{ capitalize($tc('address')) }}</span>
      </b-col>


      <!--    Street-->
      <b-col
          v-if="isSimpleAddress"
          md="8">
        <field
            :name="'street'"
            :rules="'required'"
            :model.sync="address.street"
        />
      </b-col>

      <!--    Country-->
      <b-col :md="isSimpleAddress?4:6">
        <field-select
            :model.sync="address.country"
            :name="'country'"
            :options="countries"
            :label="'country'"
            :reduce="'code'"
            :isRequired="true"
        />
      </b-col>

      <!--    Street-->
      <b-col
          v-if="!isSimpleAddress"
          md="12">
        <field
            :name="'street'"
            :rules="'required'"
            :model.sync="address.street"
        />
      </b-col>

      <!--    zipCode-->
      <b-col md="6">
        <field
            :name="'zipCode'"
            :model.sync="address.zipCode"
        />
      </b-col>

      <!--    City-->
      <b-col md="6">
        <field
            :name="'city'"
            :rules="'required'"
            :model.sync="address.city"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import Field from '@/components/input/Input'
import FieldSelect from '@/components/input/Select'
import store from '@/store'
import { capitalize } from '@/utils/filter'

export default {
  components: {
    Field,
    FieldSelect
  },
  props: {
    address: {
      type: Object,
      default: () => {}
    },
    siren: {
      type: String,
      default: ''
    },
    isSimpleAddress: {
      type: Boolean,
      default: false
    },
    displaySiren: {
      type: Boolean,
      default: true
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const countries = computed(() => {
      return store.getters['address/getCountries']
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      capitalize,

      countries,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
    // console.log(this.address)
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>