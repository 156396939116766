<template>
  <b-modal
      id="1"
      :title="title"
      v-model="isOpenLocal"
      size="sm"
      scrollable
      :hide-footer="hideFooter"
      :ok-title="capitalize($t('validate'))"
      :cancel-title="capitalize($t('cancel'))"
      @hide="tryClosePaymentModal"
      @hidden="handleCancel"
      @ok="handleOk"
  >
    <validation-observer
        ref="observerRef"
        #default="{handleSubmit, errors}">
      <b-form
          @keydown.enter.stop.prevent=""
          @submit.prevent="handleSubmit(submitValidatedPaymentLocal)"
      >
        <form-payment
            :payment.sync="paymentLocal"
            :maxAmount="formMaxAmount"
            :formErrors="errors"/>

        <button ref="submitPaymentFormRef"
                class="d-none"></button>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { capitalize } from '../../utils/filter'
import { isObject } from '../../utils/utils'

import useAPI from '../../utils/useAPI'
import FormPayment from '../form/Payment'

export default {
  components: {
    ValidationObserver,
    FormPayment
  },
  props: {
    payment: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    formMaxAmount: {
      type: Number,
      default: null
    }

  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const paymentLocal = ref(props.payment)
    const submitPaymentFormRef = ref(null)
    const observerRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { currentBreakPoint, bankAccounts } = useAPI()

    const hideFooter = computed(() => {
      return !['xs', 'sm'].includes(currentBreakPoint.value)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.isOpen, val => {
      isOpenLocal.value = val
    })
    watch(() => props.payment, val => {
      paymentLocal.value = val
    })

    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(paymentLocal, (val) => {
      emit('update:payment', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryClosePaymentModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitPaymentFormRef.value.click()
      }
    }

    const submitValidatedPaymentLocal = () => {
      emit('submitValidated')
    }

    const handleOk = async (bvModalEvent) => {
      bvModalEvent.preventDefault()

      const isValid = await observerRef.value.validate()
      if (isValid) {
        submitValidatedPaymentLocal()
      }
    }

    const handleCancel = () => {}

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      isOpenLocal,
      paymentLocal,
      submitPaymentFormRef,
      observerRef,

      // Computed
      hideFooter,
      bankAccounts,

      // Methods
      tryClosePaymentModal,
      submitValidatedPaymentLocal,
      handleOk,
      handleCancel,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    // payment: function (val) {
    //   this.paymentLocal = val
    // },
    // isOpen: function (val) {
    //   this.isOpenLocal = val
    // },
  },
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
.modal-body {
  background-color: #f8f8f8;
}
</style>