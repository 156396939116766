<template>
  <div>
    <div class="row mb-2">

      <!--    Buttons-->
      <buttons
          v-if="!readOnly"
          :title="title"
          :allow-add="allowAdd"
          :allow-add-by-upload="allowAddByUpload"
          :is-add-buttons-loading="isAddButtonsLoading"
          :text-button-add="textButtonAdd"
          :is-button-add-disabled="isButtonAddDisabled"
          @addItem="$emit('addItem')"
          @addItemByUpload="$emit('addItemByUpload')"
      >
        <template #bottomTitle>
          <slot name="bottomTitle" />
        </template>
      </buttons>

      <!--     Search filters-->
      <search-filter
          :items="items"
          :current-page="currentPage"
          :per-page.sync="perPage"
          :selected-filters.sync="selectedFilters"
          :query.sync="query"
          :filters="filters"
          :columns="columns"
          :context="context"
          :display-custom-fields="displayCustomFields"
          :selected-view.sync="selectedView"

          @selectFilter="selectFilter"
      />
    </div>

    <slot name="sectionsTop"
          class="mb-2"/>

    <!--      Grid view-->
    <grid-view
        v-if="selectedView == 'grid' && !openViewWindow"
        :items="items"
    >
      <template #gridView="{item}">
        <slot name="gridView" :item="item" />
      </template>
    </grid-view>

    <!--      List view-->
    <list-view
        v-if="selectedView == 'list' && !openViewWindow"
        :items="items"
        :columns="columns"
        :sort-column.sync="sortColumn"
        :sort-order.sync="sortOrder"
        :is-action-column-enabled="isActionColumnEnabled"
        :selectable="selectable"

        @selectItem="$emit('selectItem', $event)"
        @rowSelected="$emit('rowSelected', $event)"
    >
      <template #listView_cell_actions="{item}">
        <slot name="listView_cell_actions"
              :item="item">
        </slot>
      </template>

    </list-view>

    <div
        v-if="openViewWindow"
        class="col-12"
    >
      <view-window :items="items">
        <template #leftViewWindow="{item}">
          <slot name="leftViewWindow"
                :item="item"/>
        </template>
        <template #windowView>
          <slot name="windowView"/>
        </template>
      </view-window>
    </div>

    <!--      Pagination-->
    <pagination
        class="mt-1"
        :current-page.sync="currentPage"
        :context="context"
    />

  </div>
</template>

<script>

import mixinBase from './mixinBase'

export default {
  mixins: [mixinBase]
}
</script>

<style lang="scss">

</style>