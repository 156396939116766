<template>
  <b-row>
    <b-col cols="12">
      <b-pagination
          v-model="currentPageLocal"
          :total-rows="context?context.total_results:0"
          :per-page="context?context.per_page:25"
          first-number
          align="center"
          last-number
          prev-class="prev-item"
          next-class="next-item"
      >
        <template #prev-text>
          <icon icon="chevron-left"/>
        </template>
        <template #next-text>
          <icon icon="chevron-right"/>
        </template>
      </b-pagination>
    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {},
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    context: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const currentPageLocal = ref(props.currentPage)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(() => props.currentPage, val => {
      if (val != currentPageLocal.value) {
        currentPageLocal.value = val
      }
    })

    watch(currentPageLocal, val => {
      emit('update:currentPage', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      currentPageLocal,

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>