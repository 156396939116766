import { ref } from '@vue/composition-api/dist/vue-composition-api'
import { capitalize } from '../../../utils/filter'

import moment from 'moment'

export const useSearchFilter = () => {
  // ------------------------------------------------
  // Data
  // ------------------------------------------------
  const selectedView = ref('grid')
  const itemViewOptions = ref([
    { icon: 'th-large', value: 'grid' },
    { icon: 'list', value: 'list' },
  ])

  // ------------------------------------------------
  // Computed
  // ------------------------------------------------

  // ------------------------------------------------
  // Watch
  // ------------------------------------------------

  // ------------------------------------------------
  // Methods
  // ------------------------------------------------
  const toogleItem = (item, isOut) => {
    if (item.children) {

      if (!isOut) {
        item.showSubMenu = true
      } else {
        if (!item.showSubMenu) {
          item.showSubMenu = true
        } else {
          item.showSubMenu = false
        }
      }
    }
  }

  const getLast3months = (columnObject) => {
    let now = moment()
    let nowSub1 = moment().subtract(1, 'month')
    let nowSub2 = moment().subtract(2, 'month')

    return [
      {
        text: capitalize(now.format('MMMM')),
        key: '_date_month_filter',
        searchCriteria: [{
          column_name: columnObject.key,
          comparator: 'like',
          search_text: now.format('YYYY-MM'),
        }]
      },
      {
        text: capitalize(nowSub1.format('MMMM')),
        key: '_date_month_filter',
        searchCriteria: [{
          column_name: columnObject.key,
          comparator: 'like',
          search_text: nowSub1.format('YYYY-MM'),
        }]
      },
      {
        text: capitalize(nowSub2.format('MMMM')),
        key: '_date_month_filter',
        searchCriteria: [{
          column_name: columnObject.key,
          comparator: 'like',
          search_text: nowSub2.format('YYYY-MM'),
        }]
      },
    ]
  }

  // ------------------------------------------------
  // Mounted
  // ------------------------------------------------

  // ------------------------------------------------
  // Setup
  // ------------------------------------------------
  return {
    // Components

    // Data
    selectedView,
    itemViewOptions,

    // Computed

    // Methods
    toogleItem,
    getLast3months,
  }
}